
import { getList, confirm, appeal ,getNotConfirmCount} from "./api";
import conformModal from "./components/confirm"
import appealModalZD from "./components/appealZD.vue"
import appealModal from "./components/appeal.vue"
import viewProcess from "./components/viewProcess"
import { detail } from "@/views/financialModule/assessmentNotice/detail/api";
export default {
  name: "terminalInspectionRecord",
  components: { conformModal, appealModal, appealModalZD,viewProcess },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/assessmentNotice",
          name: "考核通知单列表",
          title: "考核通知单列表"
        }
      ],
      columns: [
        {
          label: "通知单编码",
          prop: "noticeCode",
          width: 200,
        },

        {
          label: "营销中心（编码-名称）",
          prop: "salesCenterAndName",
          width: 250,
        },
        // {
        //   label: "通知单类型",
        //   prop: "noticeTypeName",
        //   width: 200,
        // },
        {
          label: "通知单下发时间",
          prop: "noticeTime",
          width: 200,
        },
        {
          label: "缺口数(个)",
          prop: "differenceCount",
          width: 200,
        },
        {
          label: "应完成（个）",
          prop: "exceptCount",
          width: 200,
        },
        {
          label: "实际完成（个）",
          prop: "actualCount",
          width: 200,
        },
        // {
        //   label: "缺口",
        //   prop: "differenceCount",
        //   width: 200,
        // },
        {
          label: "减免缺口",
          prop: "assessDecreaseCount",
          width: 200,
        },
        {
          label: "实际考核缺口",
          prop: "actualAssessCount",
          width: 200,
        },
        {
          label: "实际考核金额",
          prop: "shijiMoney",
          width: 200,
        },
        {
          label: "确认时间",
          prop: "affirmTimeStr",
          width: 200,
        },
        {
          label: "申诉减免缺口（个）",
          prop: "appealDecreaseCount",
          width: 200,
        },
        {
          label: "申诉原因",
          prop: "appealReason",
          width: 200,
        },
        {
          label: "原因说明",
          prop: "reasonExplain",
          width: 200,
        },
        {
          label: "同意减免缺口",
          prop: "agreeDecreaseCount",
          width: 200,
        },
        {
          label: "审批意见",
          prop: "appealCheckContent",
          width: 200,
        },
        {
          label: "状态",
          prop: "noticeStatusName",
          width: 200,
        },
        {
          label: "申诉时间",
          prop: "appealTime",
          width: 200,
        },
        {
          label: "申诉图片",
          slot: "imgGroup",
          ellipsis: true,
          width:200,
        },
        // {
        //   label: "失败原因",
        //   prop: "fillReason",
        //   width: 200,
        // },
        // {
        //   label: "考核金额（元）",
        //   prop: "assessMoney",
        //   width: 200,
        // },
        // {
        //   label: "申诉完成后缺口（个）",
        //   prop: "finalCount",
        //   width: 200,
        // },
        // {
        //   label: "申诉后考核金额（元）",
        //   prop: "finalMoney",
        //   width: 200,
        // },

        // {
        //   label: "申诉结果",
        //   prop: "appealResult",
        //   width: 200,
        // },



        {
          label: "操作",
          slot: "btnClick",
          fixed: 'right',
          align:'left',
          width: 200,
        },
      ],
      tableData: [
        {
        id: 11,
          noticeType:'ZDGF'
      }
      ],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      ssearchList: {},
      searchNumber: 0,
      isLoading: false,
      tableLoading: false,
      selectedRowKey: [],
      selectedRowKeys: '',
      rowDta: '',
      confirmId: '',// 确认的id
      visibleAppeal: false,
      visibleAppealZD:false,
      visible: false,
      visibleprocess: false,
      assessMoney: 0,
      differenceCount: 0,
      type: '',
      status: '',
      notConfirmCount: 0,//未确认条数
      imgURL:null,
      onluyOneMessage:false,
      MarketTableList1:[],
      noticeRow:{},
      noticeRowId1:''
    };
  },

  mounted() {
    this.tablelist();


  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#ffffff");
  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  methods: {
    cherkImg(item) {
      this.onluyOneMessage = true
      this.imgURL = item.split(',')
    },
    // 获取未确认条数
    // getNotConfirmCounts() {
    //   let data = this.$store.state.user.userInfo.customer.customerCode
    //   getNotConfirmCount(data).then(res=>{
    //     if(res.data.code !== 400) {
    //       this.notConfirmCount = res.data
    //     }
    //
    //   })
    // },
    formatDate(value) {
      if (typeof (value) == 'undefined') {
        return ''
      } else {
        let date = new Date(parseInt(value))
        let y = date.getFullYear()
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        let s = date.getSeconds()
        s = s < 10 ? ('0' + s) : s
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
      }
    },
    // 查看详情
    gotodetail(id, noticeStatusName,row) {
      if(row.noticeType == 'ZDGF') {
        let routeUrl = this.$router.resolve({
          path: "/assessmentNotice/detailZD",
          query: {
            id,
            noticeStatusName
          }
        });
        window.open(routeUrl.href, "_blank");
      } else{
        let routeUrl = this.$router.resolve({
          path: "/assessmentNotice/detail",
          query: {
            id,
            noticeStatusName
          }
        });
        window.open(routeUrl.href, "_blank");
      }

    },
    // 选择项更改
    onChange(selRows) {
      this.selectedRowKey = selRows
    },
    // 打开申诉弹窗
    changeStatus(rowId, row) {
      if(row.noticeType == 'ZDGF') {
        this.differenceCount = 0
        this.assessMoney = 0
        this.visibleAppealZD = true
        this.confirmId = rowId
        this.differenceCount = row.actualAssessCount
        this.type = row.noticeTypeName
        this.assessMoney = row.actualAssessMoney
        this.noticeRow = row
        let data = row.id;
        detail(data).then(res => {
          this.noticeRowId1 =  res.data.data.noticeList&& res.data.data.noticeList[0]?res.data.data.noticeList[0].id:''
        })
        this.$forceUpdate()
      } else{
        this.noticeRow = row
        this.confirmId = rowId
        let data = row.id;
        this.pageLoadFlag = true
        detail(data).then(res => {
          this.MarketTableList1 = res.data.data.noticeList
        })

        this.visibleAppeal = true
      }

    },
    // 打开流程弹窗
    openLiucheng(rowId, row) {
      this.status =''
      this.visibleprocess = true
      this.status = row.appealSpStatus
    },

    // 打开弹窗
    confirModal(row) {
      this.visible = true
      this.confirmId = row
    },
    // 确认
    confirm1() {
      let data = this.confirmId
      confirm(data).then(res => {
        if (res.data.type == 'success') {
          this.$message.success('确认成功,' + res.data.text)
        } else {
          this.$message.warning('确认失败,' + res.data.text)
        }
      })
      this.tablelist()

    },
    appealConfirm1($event) {
      let sumData = $event
      // 获取出来对应的附件id
      sumData.forEach(item=>{
        item.fileIds = []
        item.fileList.forEach(items2=>{
          item.fileIds.push(items2.id)
        })
      })
      let appealList = []
      sumData.forEach(dataItem=>{
        appealList.push({
          appealDecreaseCount:dataItem.grievanceGap,
          appealReason:dataItem.reasonAppeal,
          appealFile:dataItem.fileIds.join(','),
          id:dataItem.matklName,
        })
      })
      let data = {
        id: this.confirmId,
        appealList: appealList,
      };
      appeal(data).then(res => {
        if (res.data.type == "success") {
          this.$message.success("申诉成功," + res.data.text);
          this.tablelist()

        } else {
          this.$message.warning("申诉失败," + res.data.msg);
          this.tablelist()

        }
        this.tablelist()

      });
    },
    // 申诉确认
    appealConfirm($event, value, value1, value2) {
      let dataArr = []
      dataArr.push({
        id: this.noticeRowId1,
        appealDecreaseCount: value,
        appealReason: value1,
        appealFile: value2.length > 0 ? (value2.map(item => item.id)).join(','): ''
      })
      let data = {
        id: this.confirmId,
        appealList:dataArr
      }
      appeal(data).then(res => {
        if (res.data.type == 'success') {
          this.$message.success('申诉成功,' + res.data.text)
          this.tablelist()
        } else {
          this.$message.warning('申诉失败,' + res.data.msg)
          this.tablelist()
        }
        this.tablelist()
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 搜索
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.tablelist();
    },
    // 搜索
    searchFun(arg) {

      this.pager.pageNo = 1
      this.ssearchList = {
        ...this.ssearchList,
        ...arg[0]
      };
      this.tablelist();
    },
    // 列表查询
    tablelist() {
      let data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        ...this.ssearchList
      }
      this.tableLoading = true
      getList(data).then(res => {

        if (res.data.code == 0) {
          this.tableData = res.data.list;
          if (res.data.list) {
            this.searchNumber = res.data.totalRows;
            this.pager.count = res.data.totalRows;
            this.notConfirmCount = res.data.totalRows;
            if (res.data.list.length > 0) {
              // this.notConfirmCount = res.data.list[0].notConfirmCount
              this.tableData.forEach(item => {
                item.shijiMoney = ''
                if(!item.appealResult || item.appealResult !== '申诉成功') {
                  item.shijiMoney = item.actualAssessMoney
                } else {
                  item.shijiMoney = item.finalMoney
                }

                // 图片
                item.urls = []
                if(item.attaches && item.attaches.length > 0) {
                  item.attaches.forEach(attachesItem=>{
                    item.urls.push(attachesItem.attachPath)
                  })
                }
                item.salesCenterAndName = item.salesCenterCode + '-' + item.salesCenterName

              })
            } else  {
              this.notConfirmCount = 0
            }

          }
          this.tableLoading = false
        } else {
          this.$message.warning(res.data.msg)
          this.tableLoading = false
        }
        this.tableLoading = false
      });
    },


    // 查看详情
    toDetail(row) {
      let routeUrl = this.$router.resolve({
        // path: "/center/terminalInspectionRecord/terminalInspectiondetail",
        query: { id: row.id },
      });
      window.open(routeUrl.href, "_blank");
    },
  }
};
