<template>
  <a-modal
    v-model="visible"
    @ok="handleOk"
    @cancel="cancle"
    wrapClassName="cancelNameaaaa"
    :width="1055"
    :closable="false"
    centered
    :maskClosable="false"
  >
    <div class="modal_close" @click="cancle">
      <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
    </div>
    <div class="modal_title_box">
      <span class="modal_title">
        <i class="modal_title_line"></i>考核通知单申诉
      </span>
    </div>
    <div class="choseBox">
      <div style="display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;">
        <a-button
          style="position: relative;margin-right:24px;background:#00AAA6;color:#fff;border:none"
          @click="addline()"
        >添加行
        </a-button
        >
        <a-button
          style="position: relative;background:#00AAA6;color:#fff;border:none"
          @click="delLine()"
        >删除行
        </a-button
        >
      </div>
      <div class="tableBox">
        <a-table
          :scroll="{ x: 1000, }"
          :row-selection="{
                    selectedRowKeys: selectedRowKeyscypl,
                    onChange: onSelectChangecypl
                  }"
          :columns="columns1"
          :data-source="tableData1"
          :pagination="false"
          bordered
        >
          <template slot="AssessmentContent" slot-scope="text, record, index">
            <a-select
              style="width:200px"
              placeholder="请选择"
              class="chose-account"
              show-search
              :defaultValue="record.matklName"
              option-filter-prop="children"
              :filter-option="filterOption"
              @change="handleChangeshopList2(record, index)"
              v-model="record.matklName"
              dropdownMatchSelectWidth
            >
              <a-select-option
                v-for="item in MarketTableList"
                :value="item.id"
                :key="item.id"
              >{{ item.noticeTypeName }}
              </a-select-option
              >
            </a-select>
          </template>
          <template slot="grievanceGap" slot-scope="text, record">
            <a-input style="width:180px" :value="record.grievanceGap" v-model="record.grievanceGap"
                     @change="changeNum(record)" />
          </template>
          <template slot="appealAmount" slot-scope="text, record">
            <a-input style="width:180px" :value="record.appealAmount" disabled v-model="record.appealAmount" />
          </template>
          <template slot="reasonAppeal" slot-scope="text, record">
            <a-input style="width:180px" :value="record.reasonAppeal" v-model="record.reasonAppeal" />
          </template>
          <template slot="fileList" slot-scope="text, record">
            <div class="uploadBox">
              <a-upload
                name="file1"
                :multiple="false"
                :maxCount="3"
                :data="{fileType:'personPic'}"
                :action="uploadUrl"
                :showUploadList="false"
                @change="upload($event,record)"
                :before-upload="beforeUpload"
              >
                <!-- 限制数量3张 -->
                <a-button :loading="loading" v-if="record.fileList.length < 3">
                  <a-icon type="upload" />
                  文件上传
                </a-button>
              </a-upload>
              <div class="fileListBox">
                <div class="fileListItem"
                     v-for="(item,fileIndex) in record.fileList"
                     :key=fileIndex
                >
                  <p>{{ item.name }}</p>
                  <i @click="handleRemove(fileIndex,item,record)" class="iconfont icon-fujianshanchu"></i>
                </div>

              </div>
            </div>
          </template>
        </a-table>
      </div>
    </div>
  </a-modal>
</template>

<script>
import axios from "axios";
import { publicPath } from "@/common/constant.js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "appeal-modal",

  data() {
    return {
      selectedRowKeyscypl: [],
      tableData1: [],
      columns1: [
        {
          title: "考核类型*",
          dataIndex: "AssessmentContent",
          width: 240,
          scopedSlots: { customRender: "AssessmentContent" }
        },
        {
          title: "申诉减免缺口*",
          width: 240,
          dataIndex: "grievanceGap",
          scopedSlots: { customRender: "grievanceGap" }
        },
        {
          title: "申诉减免金额*",
          width: 240,
          dataIndex: "appealAmount",
          scopedSlots: { customRender: "appealAmount" }
        },
        {
          title: "申诉原因*",
          width: 240,
          dataIndex: "reasonAppeal",
          scopedSlots: { customRender: "reasonAppeal" }
        },
        {
          title: "证明文件",
          width: 600,
          dataIndex: "fileList",
          scopedSlots: { customRender: "fileList" }
        }
      ],
      uploadUrl: publicPath + "/comm/method=uploadFile.nd",
      confirmLoading: false,
      reasonId: "",
      reduceGap: "", // 申诉减免缺口
      textarea: "",
      reason: [
        // 不合作未在CIS系统冻结、重复门店、新建店未开业、其他；其他类型不显示申诉原因。
        {
          code: 1,
          name: "不合作未在CIS系统冻结"
        },
        {
          code: 2,
          name: "重复门店"
        },
        {
          code: 3,
          name: "新建店未开业"
        },
        {
          code: 4,
          name: "其他"
        }
      ],

      fileList: [],
      fileIds: [],
      showReason: true,
      assessMoney1: 0,
      differenceCount1: 0,
      MarketTableList: [],
      loading: false
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    assessMoney: {
      type: Number
    },
    differenceCount: {
      type: Number
    },
    type: {
      type: String
    },
    MarketTableList1: {
      type: Array
    }
  },
  watch: {
    MarketTableList1: function() {
      this.MarketTableList = this.MarketTableList1;
    }
  },
  mounted() {
  },
  methods: {
    beforeUpload(file) {
      if (file.size / 1024 / 1024 <= 10) {
        this.fileList = [...this.fileList, file];
      } else {
        this.$message.info("图像超出10M,请重新上传");
      }
      // return false;
    },
    // 上传图片处理
    upload(info, record) {
      if (info.file.size/ 1024 / 1024 >= 10) {
        return;
      }
      if (info.file.status === "done") {
        if (info.file.response.message == "上传成功") {
          // push到每行的filelist
          record.fileList.push({
            name: info.file.name,
            id: info.file.response.businessId
          });
        } else {
          this.$message.warning("上传失败," + info.file.response.message);
        }
      }
    },
    // 删除附件
    handleRemove(file, item, record) {
      const index = file;
      const newFileList = record.fileList.slice();
      newFileList.splice(index, 1);
      record.fileList = newFileList;
      // this.fileIds.splice(index, 1);
    },

    // 添加行
    addline() {
      this.tableData1.push({
        reasonAppeal: "",
        matklName: "",
        grievanceGap: "",
        appealAmount: "",
        fileList: [],
        standardPrice: 0
      });
    },
    // 删除行
    delLine() {
      if (this.selectedRowKeyscypl.length <= 0) {
        this.$message.warning("请至少选择一项");
      } else {
        this.tableData1 = this.tableData1.filter((item, index) => !this.selectedRowKeyscypl.includes(index));
        this.selectedRowKeyscypl = [];
      }
    },
    // 勾选行
    onSelectChangecypl(selectedRowKeys) {
      this.selectedRowKeyscypl = selectedRowKeys;
    },

    // 更改营销
    handleChangeshopList2(item, index) {
      let obj = this.MarketTableList.find((i) => {//listData就是上面的数据源
        return i.id === item.matklName;//筛选出匹配数据
      });
      item.standardPrice = obj.standardPrice;
      if (item.grievanceGap) {
        item.appealAmount = item.standardPrice * item.grievanceGap;
      }

      //  更改选项的时候判断当前数据和接口数据的值是否一致 赋值新的id
      this.MarketTableList.forEach(marItem => {
        this.tableData1.forEach(items => {
          if (marItem.matklName == items.matklName) {
            items.matklId = marItem.id;
          }
        });
      });
    },
    // 更改申诉缺口
    changeNum(record) {
      record.appealAmount = record.grievanceGap * record.standardPrice;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 判断数组是否有相同的数据
    checkRepeat(arr) {
      var array = [];
      arr.forEach((item) => {
        if (item) {
          array.push(item.matklName);
        }
      });
      if ((new Set(array)).size != arr.length) {
        return true;
      }
      return false;
    },
    handleOk() {
      if (this.tableData1.length <= 0) {
        this.$message.warning("申诉内容不能为空");
        return;
      }
      if (this.tableData1.length > 0) {
        for (let i = 0; i < this.tableData1.length; i++) {
          if (this.tableData1[i]) {
            if (this.checkRepeat(this.tableData1)) {
              this.$message.warning("考核类型不能相同,请重新选择");
              return;
            }
            if (!this.tableData1[i].matklName) {
              this.$message.warning(`第${i + 1}行申诉类型不能为空,请选择`);
              return;
            }
            if (!this.tableData1[i].grievanceGap) {
              this.$message.warning(`第${i + 1}行申诉减免缺口不能为空,请填写`);
              return;
            }
            if (!this.tableData1[i].reasonAppeal) {
              this.$message.warning(`第${i + 1}行申诉原因不能为空,请填写`);
              return;
            }

          }
        }
      }

      this.$emit("update:visible", false);
      this.$emit(
        "confirm",
        this.tableData1
      );
      // this.tableData1 = []
    },
    //  handleOk(){
    //   // this.$emit('update:visible',false)
    //   this.$emit('cancelReasonHandOk',this.reason,this.FXDLlnum)
    //   this.textarea = ''
    //   this.form.resetFields();
    // },
    cancle() {
      this.$emit("update:visible", false);
      this.reasonId = "";
      this.reduceGap = "";
      this.textarea = "";
      this.fileIds = [];
      this.fileList = [];
      this.assessMoney1 = this.assessMoney;
      this.differenceCount1 = this.differenceCount;
    }
  }
};
</script>
<style lang="less" scoped>
.ant-upload-list {
  display: none !important;
}
/deep/ .cancelNameaaaa {
  .ant-modal-content {
    /deep/ .ant-upload-list {
      display: none !important;
    }
    .ant-upload-list {
      display: none !important;
    }
    text-align: center;
    border-radius: 10px;

    .ant-modal-body {
      //   padding: 30px 0px 0 40px;
      padding-bottom: 24px;
      padding-left: 30px;
      padding-right: 30px;

      .cancelContent {
        text-align: left;
        padding: 0 40px 0 0;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        line-height: 20px;

        .ant-tree-node-selected {
          background-color: red;
        }

        .ant-select-auto-complete.ant-select .ant-input {
          width: 300px;
        }

        .ant-form {
          line-height: 32px;
        }

        .ant-form-item-control-wrapper {
          margin-left: 16px;
        }

        .ant-form-item-control {
          line-height: 32px;
        }

        .ant-form-item-label {
          line-height: 32px;
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .messageInfo {
          font-size: 12px;
          font-weight: 400;
          color: #aaaaaa;
          line-height: 12px;
          margin-top: 8px;
          margin-left: 86px;
        }
      }

      .cancelInfo {
        padding: 24px 0 50px 0px;
        text-align: left;
        overflow: hidden;

        .cancleZi {
          width: 100px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          float: left;
          padding-left: 11px;
          margin-right: 9px;
        }

        .ant-input-number {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input {
          width: 400px;
          height: 88px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        .ant-input-affix-wrapper {
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;

        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }

      .modal_close:hover {
        background: #e4f8f7;

        .modal_close_icon {
          color: #08aba8;
        }
      }

      .modal_title_box {
        text-align: center;
        padding-bottom: 34px;

        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 1px;

          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #e4f8f7;
            z-index: -10;
          }
        }
      }

      .info {
        text-align: left;
        font-size: 18px;
        font-weight: 400;
        color: #262626;
        line-height: 30px;
        margin: 0 auto;
        margin-bottom: 80px;
        width: 400px;
      }
      /deep/ .ant-upload-list {
        display: none !important;
      }
      .ant-upload-list {
        display: none !important;
      }
      .tableBox {
        /deep/ .ant-upload-list {
          display: none !important;
        }
        .ant-upload-list {
          display: none !important;
        }

        /deep/ .ant-upload {
        }

        .uploadBox {
          display: flex;
          flex-direction: row;
          align-items: center;
          //justify-content: center;
          /deep/ .ant-upload-list {
            display: none !important;
          }
          .ant-upload-list {
            display: none !important;
          }
          /deep/ .ant-upload:hover {
            button {
              background: #ECF8F8;
              border-color: #ECF8F8;
            }
          }
        }

        .fileListBox {
          margin-left: 6px;
          .fileListItem {
            cursor: pointer;
            margin-bottom: 4px;
            min-width: 162px;
            display: flex;
            //justify-content: space-between;
            align-items: center;

            p {
              padding-left: 6px;
              font-size: 14px;
              color: #1890FF;
            }

            i {
              color: #aaa;
              padding-right: 8px;
              margin-left: 80px;
            }


          }

          .fileListItem:hover {
            background: #F7F7F7;
          }
        }
      }
    }

    p {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #262626;
      line-height: 30px;
    }

    .ant-modal-footer {
      text-align: center;
      border-top: 0px solid #fff;
      padding: 0px 0 30px;

      .ant-btn {
        width: 120px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        color: #777;
      }

      .ant-btn-primary {
        color: #fff;
        background: #00aaa6;
        margin-left: 24px;
      }
    }
  }
}
</style>


